import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/HeroSection.js";
import Services from "components/ServicesSection.js";
import AboutUs from "components/AboutUsSection.js";
import WhyUs from "components/WhyChooseUsSection.js";
import Products from "components/ProductsSection.js";
import ContactUs from "components/ContactUsSection.js";
import Footer from "components/Footer.js";


export default () => {
  
  return (
    <AnimationRevealPage>
      <Hero/>
      <AboutUs/>
      <Products/>
      <Services/>
      <WhyUs/>
      <ContactUs/>
      <Footer />
    </AnimationRevealPage>
  );
}
