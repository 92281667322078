import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import powerSteeringImageSrc from "images/power-steering.svg";
import linkagePartsImageSrc from "images/linkage-parts.svg";
import forgedComponentsImageSrc from "images/forged-compnents.svg";

const Heading = tw(SectionHeading)`text-black`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;
const HighlightedText = tw.span`bg-orange-1000 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  heading=<>Our Comprehensive  <HighlightedText>Services.</HighlightedText></>,
  cards=[
    {
      imageSrc: powerSteeringImageSrc,
      title: "Power Steering Components",
      description: "We offer a wide range of power steering components, including power steering pumps, gearboxes, and control valves. Our components are designed to provide smooth and efficient steering performance, ensuring a comfortable and safe driving experience.",
    },
    {
      imageSrc: linkagePartsImageSrc,
      title: "Linkage Parts",
      description: "From tie rods and ball joints to drag links and center links, we manufacture linkage parts that play a crucial role in the stability and maneuverability of tractors and commercial vehicles. Our parts are built to withstand rugged terrains and heavy-duty applications.",
    },
    {
      imageSrc: forgedComponentsImageSrc,
      title: "Forged Components",
      description: "Our expertise extends to the production of forged components, such as axle shafts, connecting rods, and gears. These components are forged with precision and strength, enhancing the overall performance and durability of vehicles.",
    }
  ],
  imageContainerCss= tw`p-2!`,
  imageCss= tw`w-20! h-20!`,
  linkText = "Learn More",
  subheading = "",
  description = "",
}) => {

  return (
    <Container id="services">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
