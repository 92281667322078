import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import factory from "images/factory.svg";


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-orange-1000 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const HighlightedText = tw.span`bg-orange-1000 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  subheading = <Subheading>Established Since 2013</Subheading>,
  heading = <>Crafting Precision, <wbr /> <HighlightedText>Driving Performance.</HighlightedText></>,
  description = <Description>
    Experience excellence in power steering and linkage components for tractors and commercial vehicles. Our craftsmanship since 2013 sets us apart as a trusted partner, known for superior finishing and precise designs. Discover our state-of-the-art facilities and unwavering commitment to quality. <br/><br/>Elevate your manufacturing standards with Anjaneya International—where precision meets performance.
  </Description>,
  textOnLeft = false,
  imageSrc = factory,
  imageCss = tw`rounded-4xl`,
  imageDecoratorBlob = true,
  imageDecoratorBlobCss = tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
}) => {
  return (
    <Container id="about_us" >
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
