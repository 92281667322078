import React, { useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "./headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import landingVideo from "images/landing.mp4";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const Heading = tw.h1`font-black text-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-black max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-orange-1000 text-gray-100 hocus:bg-orange-500 focus:shadow-outline focus:outline-none transition duration-300`;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const HighlightedText = tw.span`bg-orange-1000 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  heading = <>Welcome to <HighlightedText>Anjaneya International</HighlightedText></>,
  description="Revolutionize Your Tractor's Performance with our Exquisite Craftsmanship – Elevating the Standard of Power Steering Components, Linkage Parts, and Forged Components for Manufacturers Worldwide.",
  primaryButtonText="Contact Us",
  primaryButtonUrl="#contact_us",
  videoSrc = landingVideo,
}) => {

  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    const savedIsPlaying = localStorage.getItem('videoIsPlaying');
    setIsPlaying(savedIsPlaying === 'true');
  }, []);
  const handleVideoEnded = () => {
    setIsPlaying(true);
  };
  useEffect(() => {
    localStorage.setItem('videoIsPlaying', isPlaying.toString());
  }, [isPlaying]);

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
                <video
                  src={videoSrc}
                  autoPlay
                  loop
                  muted
                  controls={false}
                  onEnded={handleVideoEnded}
                  style={{ width: '100%', height: 'auto' }}
                />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        
      </Container>
    </>
  );
};
