import tie1 from 'images/products/26.png';
import tie2 from 'images/products/27.png';
import tie3 from 'images/products/48.png';
import tie4 from 'images/products/49.png';
import tie5 from 'images/products/50.png';
import tie6 from 'images/products/51.png';
import tie7 from 'images/products/52.png';
import tie8 from 'images/products/59.png';
import ball1 from 'images/products/25.png';
import ball2 from 'images/products/47.png';
import ball3 from 'images/products/63.png';
import ball4 from 'images/products/65.png';
import ball5 from 'images/products/34.png';
import ball6 from 'images/products/43.png';
import ball7 from 'images/products/44.png';
import ball8 from 'images/products/45.png';
import misc1 from 'images/products/46.png';
import misc2 from 'images/products/53.png';
import misc3 from 'images/products/61.png';
import misc4 from 'images/products/62.png';
import misc5 from 'images/products/67.png';
import misc6 from 'images/products/36.png';
import misc7 from 'images/products/37.png';
import misc8 from 'images/products/38.png';

export const Products =

{
    "Tie-Rod Ends": [
    {
      imageSrc:tie1,
      title: "FarmTrac 2 Hole Tie Rod End",
      content: "24MM",
      url: "#"
    },
    {
      imageSrc:tie2,
      title: "FarmTrac Euro Tie Rod End",
      content: "24MM",
      url: "#"
    },
    {
      imageSrc:tie3,
      title: "Sonalika 3 Cut Tie Rod End",
      content: "28MM",
      url: "#"
    },
    {
      imageSrc: tie4,
      title: "Sonalika 3 Cut Tie Rod End",
      content: "26MM",
      url: "#"
    },
    {
      imageSrc: tie5,
      title: "Sonalika 3 Cut Tie Rod End",
      content: "24MM",
      url: "#"
    },
    {
      imageSrc: tie6,
      title: "Sonalika 3 Hole Tie Rod End",
      content: "22MM",
      url: "#"
    },
    {
      imageSrc: tie7,
      title: "Sonalika Tie Rod End",
      content: "Set of 4 Pcs.",
      url: "#"
    },
    {
      imageSrc: tie8,
      title: "Swaraj Power Steering",
      content: "Set of 2 Pcs.",
      url: "#"
    }
  ],
  "Ball Joints": [
    {
      imageSrc:ball1,
      title: "Farmtrac Ball Joint",
      content: "1pc",
      url: "#"
    },
    {
      imageSrc:ball2,
      title: "Sonalika Ball Joint",
      content: "1pc",
      url: "#"
    },
    {
      imageSrc:ball3,
      title: "Mahindra Arjun Ball Joint",
      content: "1pc",
      url: "#"
    },
    {
      imageSrc: ball4,
      title: "Mahindra MKM Ball Joint",
      content: "1pc",
      url: "#"
    },
    {
      imageSrc: ball5,
      title: "Rear Axle Collar",
      content: "New Holland",
      url: "#"
    },
    {
      imageSrc: ball6,
      title: "Spindle Checknut",
      content: "John-Deere",
      url: "#"
    },
    {
      imageSrc: ball7,
      title: "Power Steering Elbow",
      content: "John-Deere",
      url: "#"
    },
    {
      imageSrc: ball8,
      title: "Lower Link Pin",
      content: "John-Deere",
      url: "#"
    }
  ],
  "Miscellaneous": [
    {
      imageSrc:misc1,
      title: "Sensor Rod",
      content: "John-Deere",
      url: "#"
    },
    {
      imageSrc:misc2,
      title: "Thrust Pad",
      content: "Swaraj",
      url: "#"
    },
    {
      imageSrc:misc3,
      title: "Lift Plunger",
      content: "Swaraj",
      url: "#"
    },
    {
      imageSrc: misc4,
      title: "Oil Break Rod",
      content: "Swaraj",
      url: "#"
    },
    {
      imageSrc: misc5,
      title: "Brake Rod",
      content: "Mahindra MKM",
      url: "#"
    },
    {
      imageSrc: misc6,
      title: "T-Pin 25MM",
      content: "John-Deere",
      url: "#"
    },
    {
      imageSrc: misc7,
      title: "T-Pin 19MM",
      content: "John-Deere",
      url: "#"
    },
    {
      imageSrc: misc8,
      title: "T-Pin 16MM",
      content: "John-Deere",
      url: "#"
    }
  ]
}